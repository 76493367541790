@value variables: "../css/variables.css";

@value BASE_COLOR, BTN_HOVER_COLOR, TABLE_THEAD_BGCOLOR from variables;

/*********************全局**************************/
:global(.explain) {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url(../images/icon_explain@2x.png);
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

:global(.mxd-hide) {
    display: none;
}

:global(.explain:hover) {
    background-image: url(../images/icon_explain_hover@2x.png);
}

/******************dtopdown*********************/
:global(.mxd-dropdown-toggle) {
    border-radius: 5px;
    border: 1px solid transparent;
    /*border: 1px solid BASE_COLOR;*/
    background-color: #F8F8FA;
    padding: 6px 12px;
    color: #232735;
    font-size: 14px;
    font-weight: bold;
}

:global(.mxd-caret) {
    color: #C8CBD9 !important;
    border-top: 6px dashed !important;
    border-right: 6px solid transparent !important;
    border-left: 6px solid transparent !important;
    margin-left: 8px;
}

:global(.mxd-dropdown-select) {
    display: inline-block;
}

:global(.mxd-dropdown-select .dropdown-toggle) {
    border: 1px solid #E5E6E9;
    border-radius: 5px;
    height: 36px;
    padding: 0 30px 0 14px;
    /*min-width: 160px;*/
    display: inline-block;
    line-height: 36px;
    position: relative;
    cursor: pointer;
}

:global(.mxd-dropdown-select .dropdown-toggle.primary) {
    background-color: BASE_COLOR;
    color: #FFFFFF;
}

:global(.mxd-dropdown-select:hover .dropdown-toggle) {
    border: 1px solid BASE_COLOR;
}

:global(.mxd-dropdown-select .mxd-caret-thin) {
    position: absolute;
    right: 10px;
    margin-top: 11px;
}

:global(.mxd-caret-thin) {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 6px;
    background-image: url(../images/icon_arrow_down2@2x.png);
    background-size: 100%;
    background-repeat: no-repeat;
}

:global(.mxd-dropdown-select .primary .mxd-caret-thin) {
    background-image: url(../images/icon_arrow_down3@2x.png);
}

:global(.mxd-dropdown-toggle:hover) {
    border: 1px solid BASE_COLOR;
    background-color: transparent;
}

:global(.mxd-dropdown-menu) {
    background: #FFFFFF;
    border: 1px solid #F8F8FA;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 12px 0;
}

/*:global(.mxd-dropdown-menu-thin) {*/
/*background: #FFFFFF;*/
/*border: 1px solid #F5F6FA;*/
/*box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);*/
/*border-radius: 5px;*/
/*padding: 12px 0;*/
/*}*/

:global(.mxd-dropdown-menu a) {
    cursor: pointer;
}

:global(.menu-item:hover) {
    background-color: #F5F5F5;
}

:global(.mxd-dropdown-menu .menu-item ) {
    display: block;
    height: 100%;
    padding: 0 20px;
    line-height: 60px;
    color: #232735;
}

/*:global(.mxd-dropdown-menu-thin a) {*/
/*cursor: pointer;*/
/*}*/

/*:global(.mxd-dropdown-menu-thin .menu-item ) {*/
/*display: block;*/
/*height: 100%;*/
/*padding: 0 20px;*/
/*line-height: 40px;*/
/*color: #232735;*/
/*width: 100%;*/
/*white-space: nowrap;*/
/*}*/

:global(.mxd-dropdown-menu .menu-item-xs) {
    display: block;
    height: 100%;
    padding: 0 20px;
    line-height: 40px;
    color: #232735;
    text-align: left;
    white-space: nowrap;
}

:global(.menu-item-xs:hover) {
    background-color: #F5F5F5;
}

:global(.point) {
    display: inline-block;
    height: 6px;
    width: 6px;
    border-radius: 6px;
    background-color: #FFFFFF;
}

:global(.point-success) {
    background-color: #37DDAC;
}

:global(.point-danger) {
    background-color: #FB607E;
}

:global(.text-running) {
    color: #37DDAC;
}

:global(.text-end) {
    color: #FB607E;
}

:global(.text-notbegin) {
    color: #FFBA00;
}

:global(.progress) {
    background-color: #F5F6FA;
    height: 10px;
}

:global(.progress-bar) {
    background-color: #37DDAC;
}

:global(.mxd-panel) {
    padding: 30px 20px;
    background: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(230, 230, 230, 0.40);
}

:global(.mxd-panel-scroll) {
    position: relative;
    overflow: hidden;
}

:global(.mxd-panel.mxd-default-height) {
    min-height: 786px;
}

:global(.mxd-norecord) {
    text-align: center;
    color: #909298;
    margin-top: 40px;
}

:global(.mxd-new-content) {
    background: #ffffff;
    padding: 30px 22px;
    box-shadow: 0 0 8px 0 rgba(230, 230, 230, 0.40);
    border-radius: 4px;
    overflow: scroll;
}

:global(.mxd-new-margin) {
    margin-top: 20px;
}

/**************自定义滚动条样式***************/
:global(.bscroll-vertical-scrollbar .bscroll-indicator) {
    border: none !important;
    width: 5px !important;
    background: rgba(0, 0, 0, 0.1) !important;
}

/*******************折叠面板*****************/
:global(.mxd-trigger) {
    height: 60px;
    width: 100%;
    font-size: 16px;
    color: #232735;
    line-height: 60px;
    border-bottom: 1px solid #E5E6E9;
    font-weight: bold;
}

:global(.mxd-triggerend) {
    height: 30px;
}

/*****************icon********************/
:global(.icon-rank) {
    background: url(../images/icon_rank@2x.png) no-repeat;
}



:global(.icon-start) {
    background: url(../images/icon_begin@2x.png) no-repeat;
}

:global(.icon-copy) {
    background: url(../images/icon_copy@2x.png) no-repeat;
}

:global(.icon-del) {
    background: url(../images/icon_del@2x.png) no-repeat;
}

:global(.icon-data) {
    background: url(../images/icon_data@2x.png) no-repeat;
}

:global(.icon-follow) {
    background: url(../images/icon_follow@2x.png) no-repeat;
}

:global(.mxd-input) {
    border: 1px solid #E5E6E9;
    border-radius: 5px;
    box-shadow: none;
    height: 32px;
}

:global(.mxd-inline) {
    display: inline-block;
    width: auto;
}

:global(.mxd-input:focus) {
    border: 1px solid BASE_COLOR;
    box-shadow: none;
}

:global(.mxd-input::placeholder) {
    color: #B0B2B8;
    font-weight: 400;
    font-size: 14px;
}

:global(.mxd-select:focus) {
    box-shadow: none;
    border: 1px solid BASE_COLOR;
}

:global(.mxd-textarea) {
    border: 1px solid #E5E6E9;
    border-radius: 5px;
    box-shadow: none;
    padding: 10px;
    width: 100%;
    resize: none;
}

:global(.mxd-textarea:focus) {
    border: 1px solid BASE_COLOR;
    box-shadow: none;
    outline: none;
}

:global(.mxd-textarea::placeholder) {
    color: #B0B2B8;
    font-weight: 400;
    font-size: 14px;
}

/**************************表单控件*************************/

/********************button********************/

:global(.mxd-btn) {
    display: inline-block;
    padding: 0 20px;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    height: 36px;
    line-height: 36px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 5px;
    border: none;
    box-shadow: none;
    outline: none;
}

:global(.mxd-btn.block) {
    display: block;
    width: 100%;
    margin-top: 20px;
}

:global(.mxd-icon-btn) {
    font-size: 0;
}

:global(.mxd-icon-btn span) {
    font-size: 16px;
    vertical-align: middle;
}

:global(.mxd-icon-btn .mxd-icon) {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 6px;
}

:global(.mxd-btn:active) {
    transform: scale(1.02);
}

:global(.mxd-btn[disabled]) {
    cursor: not-allowed;
    filter: alpha(opacity=70);
    opacity: .70;
}

:global(.mxd-btn-cancel) {
    background-color: #F5F6FA;
    color: #969696;
}

:global(.mxd-btn-cancel:hover) {
    background-color: #eaebef;
    color: #909298;
}

:global(.mxd-btn-primary) {
    background-color: BASE_COLOR;
    color: #FFFFFF;
}

:global(.mxd-btn-primary:hover) {
    background-color: BTN_HOVER_COLOR;
    color: #FFFFFF;
}

:global(.mxd-select-btn) {
    height: 36px;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    border: 1px solid BASE_COLOR;
    border-radius: 5px;
    margin: 0;
    font-size: 14px;
    color: BASE_COLOR;
    padding: 0 20px;
    line-height: 36px;
    display: inline-block;
    background-color: #FFFFFF;
}

:global(.mxd-select-btn:hover) {
    background-color: BASE_COLOR;
    color: #FFFFFF;
}

:global(.mxd-select-btn-1) {
    display: inline-block;
    height: 30px;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    border: none;
    border-radius: 5px;
    background-color: #F5F6FA;
    font-size: 14px;
    color: #909298;
    margin: 0;
    width: 80px;
    line-height: 30px;
    margin: 0 auto;
}

:global(.mxd-select-btn-1:hover) {
    background-color: BASE_COLOR;
    color: #FFFFFF;
}

/********************button********************/
:global(.word-limit-wrapper) {
    position: relative;
}

:global(.word-limit-wrapper input) {
    padding-right: 44px;
}

:global(.word-limit) {
    position: absolute;
    font-size: 12px;
    color: #909298;
    height: 20px;
    line-height: 20px;
    right: 6px;
    top: 6px;
}

/*********************模态框**********************/
:global(.mxd-modal-wrapper) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1005;
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
}

:global(.mxd-modal-wrapper.in) {
    display: block;
}

:global(.mxd-modal-wrapper.in .mxd-modal:first-child) {
    display: block;
}

:global(.mxd-modal) {
    display: none;
    position: absolute;
    z-index: 3050;
    width: 480px;
    top: 200px;
    left: calc(50% - 240px);
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0 5px 20px 0 rgba(35, 39, 53, 0.10);
    border-radius: 10px;
}

:global(.mxd-modal-footer-space) {
    height: 60px;
}

:global(.mxd-modal-header) {
    height: 26px;
    margin-bottom: 30px;
    position: relative;
}

:global(.mxd-modal-header *) {
    display: inline-block;
    line-height: 28px;
    height: 28px;
    margin: 0;
    padding: 0;
    font-family: PingFangSC-Medium;
    font-size: 20px;
    color: #232735;
    letter-spacing: 0.21px;
}

:global(.mxd-modal-header .close) {
    height: 14px;
    width: 14px;
    display: inline-block;
    float: right;
    background-image: url(../images/btn_close@2x.png);
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 1;
}

:global(.mxd-modal-footer) {
    position: absolute;
    bottom: 30px;
    right: 30px;
}

:global(.mxd-modal-footer .mxd-btn) {
    margin-left: 20px;
    float: right;
}

:global(.mxd-fade) {
    animation: linear;
    animation-duration: 1s;
    -webkit-animation: linear 1s;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fade-in { /*针对webkit内核*/
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

/*********************模态框**********************/
/*********************时间选择器**********************/
:global(.timepicker input) {
    width: 180px !important;
    background-image: url(../images/btn_date_normal@2x.png);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 150px 4px;
    background-color: #FFFFFF !important;
    cursor: pointer;
    float: left;
}

:global(.timepicker input:hover) {
    background-image: url(../images/btn_date_hover@2x.png);
}

:global(.timepicker .hline) {
    width: 20px;
    height: 1px;
    background-color: #E5E6E9;
    float: left;
    margin: 16px 10px 0 10px;
}

:global(.timepicker .hline-xs) {
    width: 10px;
    height: 1px;
    background-color: #E5E6E9;
    float: left;
    margin: 16px 5px 0 5px;
}

/*********************时间选择器**********************/

/*********************tab***********************/
:global(.mxd-tab) {
    margin: 0 0 34px 0;
    padding: 0;
}

:global(.mxd-tab li) {
    display: inline-block;
    font-size: 16px;
    color: #232735;
    margin-left: 60px;
}

:global(.mxd-tab li:first-child) {
    margin-left: 0;
}

:global(.mxd-tab li span) {
    padding-bottom: 12px;
    cursor: pointer;
    display: block;
}

:global(.mxd-tab li span:hover) {
    color: BASE_COLOR;
}

:global(.mxd-tab .active span) {
    border-bottom: 3px solid #00ABFF;
    color: BASE_COLOR;
}

:global(.mxd-a) {
    color: BASE_COLOR;
    cursor: pointer;
}

:global(.mxd-a:hover) {
    color: #0099eb;
}

:global(.mxd-a:link) {
    color: #0099eb;
}

:global(.mxd-a:active) {
    color: #0099eb;
}

/*********************tab***********************/

/*********************统计块状***********************/
:global(.blockGroup3) {
    width: 100%;
}

:global(.blockGroup3 .block) {
    width: 30%;
    float: left;
    margin-right: 5%;
}

:global(.blockGroup3 .block:last-child) {
    margin-right: 0;
}

:global(.blockGroup4) {
    width: 100%;
}

:global(.blockGroup4 .block) {
    width: 48%;
    float: left;
    margin-right: 4%;
}

:global(.blockGroup4 .block:nth-child(2n)) {
    margin-right: 0;
}

:global(.blockGroup4 .block:last-child) {
    margin-right: 0;
}

:global(.blockGroup5) {
    width: 100%;
}

:global(.blockGroup5 .block) {
    width: 18%;
    float: left;
    margin-right: 2.5%;
}

:global(.blockGroup5 .block:last-child) {
    margin-right: 0;
}

/*********************统计块状***********************/
/*********************table***********************/
:global(.mxd-table) {
    width: 100%;
    /*box-shadow: 0 1px 0 0 #E5E6E9;*/
    /*border: 1px solid #E5E6E9;*/
}

:global(.mxd-table thead) {
    background: #F0F0F0;
    /*background: TABLE_THEAD_BGCOLOR;*/
}

:global(.mxd-table tr) {
    border-bottom: 1px solid #F0F0F0;
}

:global(.mxd-table th) {
    font-size: 14px;
    color: #262626;
    letter-spacing: 0.27px;
    padding: 17px 20px;
    text-align: center;
}

:global(.mxd-table td) {
    padding: 25px 10px;
    font-size: 14px;
    color: #262626;
    letter-spacing: 0.31px;
    text-align: center;
}

:global(.mxd-table.shrink th) {
    padding: 0 10px;
}

:global(.mxd-table.shrink td) {
    padding: 0 10px;
}

@media screen and (max-width: 1600px) {
    :global(.mxd-table th) {
        padding: 17px 10px;
    }

    :global(.mxd-table td) {
        padding: 25px 10px;
    }
}

/*********************table***********************/
/*********************分页***********************/
:global(.pagination .active a) {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: BASE_COLOR !important;
    border-color: BASE_COLOR !important;
}

:global(.pagination li) {
    cursor: pointer;
}

/*********************分页***********************/
:global(.mxd-dialog) {
    background: #FFFFFF;
    border: 1px solid #F5F6FA;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.04);
    border-radius: 4px;
}

:global(.dashboard-arrow-icon) {
    display: inline-block;
    width: 4px;
    height: 4px;
    border: solid #878787;
    border-width: 1px 1px 0 0;
    -webkit-transform: scale(1.5) rotate(45deg);
    -moz-transform: scale(1.5) rotate(45deg);
    transform: scale(1.5) rotate(45deg);
    transition: all .2s ease;
    background: none;
}

:global(.collapsed .dashboard-arrow-icon) {
    -webkit-transform: scale(1.5) rotate(135deg);
    -moz-transform: scale(1.5) rotate(135deg);
    transform: scale(1.5) rotate(135deg);
}

:global(.table-titlebtn) {
    margin-bottom: 16px;
}

:global(.modal-noboder .ant-modal-header) {
    /*border-bottom: none !important;*/
}

:global(.wordAddPicTitle .rich-text-editor) {
    min-height: 130px;
    height: 130px;
}

:global(.wordAddPicTitle .rich-text-editor-scroll) {
    height: 90px;
    /*overflow: scroll;*/
}

:global(.news-rich-text .rich-text-editor) {
    min-height: 130px;
    height: 130px;
}

:global(.news-rich-text .rich-text-editor-scroll) {
    height: 90px;
    /*overflow: scroll;*/
}

/*新建按钮*/
:global(.createBtn) {
    width: 130px;
    height: 38px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 38px;
}

:global(.mxd-create-btn) {
    /*width: 130px;*/
    /*height: 38px;*/
    margin-bottom: 20px;
    /*text-align: center;*/
    /*line-height: 38px;*/
}


:global(.createIcon) {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    top: 4px;
    background-size: contain;
    right: 4px;
}
:global(.mxd-create-icon) {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    top: 4px;
    background-size: contain;
    right: 4px;
}

:global(.createBtnWord) {
    font-size: 16px;
    color: #FFFFFF;
    margin-left: 10px;
}
:global(.mxd-create-word) {
    font-size: 16px;
    color: #FFFFFF;
    margin-left: 10px;
}

:global(.mxd-fontWeight) {
    font-weight: 700;
}

:global(.mxd-fontBlureColor) {
    color: #00ABFA;
}






