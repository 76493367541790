@value variables: "./variables.css";

@value BASE_COLOR from variables;

:global(.Button) {
    display: inline-block;
    padding: 0 16px;
    font-size: 14px;
    color: #8590a6;
    text-align: center;
    cursor: pointer;
    background: none;
    border: 1px solid #ccd8e1;
    border-radius: 3px;
}

:global(.ButtonPrimary) {
    background-color: BASE_COLOR;
    color: white;
}

:global(.ButtonPrimary:hover) {
    background-color: #48cc84;
}