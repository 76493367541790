@value SiteNavBg: #FFFFFF;

@value SiteNavHeight: 60px;

@value SIDEBAR_WIDTH: 224px;

@value SIDEBORDER_COLOR: #eeeef0;

@value MinWidth: 1100px;

@value BodyBg: #F8F8FA;

@value BorderColor: #E7E7EB;

@value contentBg: #f7f7f7;

@value COLOR_SUCCESS: #5CB85C;

@value BASE_COLOR: #0c8eff;

@value BTN_HOVER_COLOR: #009AEF;

@value MENU_ITEM_HOVER_COLOR: #F5F5F5;

@value TABLE_THEAD_BGCOLOR:#f5f7fa;

@value TABLE_BORDER_COLOR:#f0f0f0;

@value SPACE: 15px;

@value BLOCK_BLUE: #40A9FF;

@value BLOCK_PINK: #FF688E;

@value BLOCK_ORANGE: #FFC71B;

@value BLOCK_GREEN: #5DDCB2;

@value BLOCK_PURPLE: #8E8AFF;
@value ACTIVE_COLOR: #0c8eff;

