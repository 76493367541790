@value variables: "../../css/variables.css";

@value BASE_COLOR from variables;

.bg {
    background-color: BASE_COLOR;
    width: 100%;
    height: 360px;
}

.contentScroll {
    z-index: 1;
    height: 100%;
}

.content {
    height: 1000px;
}

.header {
    width: 1000px;
    margin: 0 auto;
    height: 80px;
    line-height: 80px;
    padding-top: 10px;
}

.logo {
    display: inline-block;
    width: 240px;
    height: 96px;
    background: url(../../images/logo.svg) no-repeat;
    background-size: 100%;
}

.right {
    float: right;
}

.headerbtn {
    padding: 8px 30px;
    border: 1px solid white;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

.headerbtn:hover {
    background-color: white;
    color: BASE_COLOR;
}

.title {
    text-align: center;
    color: white;
    font-size: 50px;
    margin-top: 40px;
}

.vippur {
    width: 1000px;
    margin: 40px auto 0 auto;
    height: 800px;
    /*position: absolute;*/
    /*top: 120px;*/
    /*left: calc(50% - 500px);*/
    background: #FFFFFF;
    border-radius: 10px;
    padding: 30px;
}

.viptitle {
    font-size: 20px;
    color: #232735;
    font-weight: 700;
}

.puritem {
    margin-top: 20px;
    color: #8C8C8C;
    width: 100%;
}

.puritem .left {
    float: left;
    width: 60px;
    height: 50px;
    line-height: 50px;
    text-align: right;
    margin-right: 30px;
    font-weight: 700;
    font-size: 14px;
    color: #909298;
}

.puritem .right {
    float: left;
    font-size: 14px;
    color: #232735;
}

.puritem .right span {
    line-height: 50px;
}

.selectbtn {
    width: 120px;
    height: 44px;
    cursor: pointer;
    display: inline-block;
    margin-right: 15px;
    background: #F8F8FA;
    border-radius: 5px;
    line-height: 44px;
    text-align: center;
}

.price {
    color: #FF5E5E;
    line-height: 50px;
    font-weight: bold;
    font-size: 30px;
}

.selectbtnActive {
    background: #00ABFF;
    color: #FFFFFF;
    background-image: url(../../images/current_white@2x.png);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: 26px 50%;
    padding-left: 20px;
}

.custom {
    padding-top: 18px;
}

.customTip {
    text-align: center;
    margin-top: 6px;
}
