:global(.icon-del) {
    background: url(../images/icon_del@2x.png) no-repeat 100%;
}

:global(.icon-data) {
    background: url(../images/data@2x.png) no-repeat 100%;
}

:global(.icon-data:hover) {
    background-image: url(../images/data_hover@2x.png);
}

:global(.icon-users) {
    background: url(../images/nav/nav_follow_normal@2x.png) no-repeat 100%;
}

:global(.icon-users:hover) {
    background-image: url(../images/nav/nav_follwo_hover1.png);
}

:global(.icon-qrcode) {
    background: url(../images/nav/nav_code_normal@2x.png) no-repeat 100%;
}

:global(.icon-qrcode:hover) {
    background-image: url(../images/nav/nav_code_hover@2x.png);
}

:global(.icon-more) {
    background: url(../images/more@2x.png) no-repeat 100%;
}

:global(.icon-more:hover) {
    background-image: url(../images/more@2x.png);
}

:global(.icon-copy) {
    background: url(../images/copyNew.png) no-repeat 100%;
}

/*添加模板的加号*/
:global(.icon-create){
    background: url(../images/msg/icon_add@2x.png) no-repeat 100%;
}
/*模板*/
:global(.icon-templateMsg){
    background: url(../images/msg/icon_message——w@2x.png) no-repeat 100%;
}
/*模板选中*/
:global(.icon-templateMsgActive){
    background: url(../images/msg/icon_messag_hover@2x.png) no-repeat 100%;
}
/*模板和图片*/
:global(.icon-templatePic){
    background: url(../images/msg/icon_images_w@2x.png) no-repeat 100%;
}
/*模板和图片选中*/
:global(.icon-templatePicActive){
    background: url(../images/msg/icon_images_hover@2x.png) no-repeat 100%;
}
/*模板或者图片*/
:global(.icon-templateOrPic){
    background: url(../images/msg/templateOrPic@2x.png) no-repeat 100%;
}
/*模板或者图片选中*/
:global(.icon-templateOrPicActive){
    background: url(../images/msg/templateOrPicActive@2x.png) no-repeat 100%;
}
/*订阅号*/
:global(.icon-dingyuehao){
    background: url(../images/msg/icon_d@2x.png) no-repeat 100%;
    display: inline-block;
    width: 17px;
    height: 17px;
    position: relative;
    top: -2px;
    background-size: contain;
}
/*服务号*/
:global(.icon-fuwuhao){
    background: url(../images/msg/icon_f@2x.png) no-repeat 100%;
    display: inline-block;
    width: 17px;
    height: 17px;
    position: relative;
    top: -2px;
    background-size: contain;
}
/*选择模板提示感叹号*/
:global(.icon-templateMsgTips){
    background: url(../images/msg/icon_remind@2x.png) no-repeat 100%;
}

/*用户头像*/
:global(.icon-createUserPic){
    background: url("../images/msg/icon_images_g@2x.png") no-repeat 100%;
}

:global(.icon-task){
    background: url(../images/icon/task.png) no-repeat;
}
:global(.icon-qudaoma){
    background: url(../images/icon/ma2.png) no-repeat;
}
:global(.icon-share){
    background: url(../images/icon/share.png) no-repeat;
}
:global(.icon-msg){
    background: url(../images/icon/msg.png) no-repeat;
}
:global(.icon-warn){
    background: url(../images/icon/warn.png) no-repeat;
}
:global(.icon_arrow_down){
    background: url("../images/icon_arrow_down2@2x.png") no-repeat 100%;
}
:global(.icon_success){
    background: url("../images/icon/success.png") no-repeat 100%;
}
:global(.icon_upload){
    background: url("../images/img_upload@2x.png") no-repeat 100%;
}
:global(.icon_wx){
    background: url("../images/icon/wx.png") no-repeat 100%;
}
:global(.icon_card){
    background: url("../images/icon/card.png") no-repeat 100%;
}
:global(.icon-kefu){
    background: url("../images/icon/kefu.png") no-repeat 100%;
}
:global(.icon-man){
    background: url("../images/icon/man.png") no-repeat 100%;
}
:global(.icon-woman){
    background: url("../images/icon/woman.png") no-repeat 100%;
}




/*添加标签*/
:global(.icon-addGroupTags){
    background: url("../images/msg/icon_group@2x.png") no-repeat 100%;
}
/*选择模板右上角的对号*/
:global(.icon-selectTemplate){
    background: url("../images/order/active.png") no-repeat 100%;
}
/*上传图片图标*/
:global(.icon-uploadPic){
    background: url("../images/addcir@2x.png") no-repeat 100%;
}
/*标签删除图标*/
:global(.icon-tagSelectDel){
    background: url("../images/btn_del_hover@2x.png") no-repeat 100%;
}





