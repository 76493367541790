@value variables: "../../css/variables.css";

@value SIDEBAR_WIDTH, BASE_COLOR, SIDEBORDER_COLOR, SiteNavHeight, SiteNavBg, MinWidth from variables;

.siteNav {
    /*width: calc(100% - 224px);*/
    width: 100%;
    height: SiteNavHeight;
    background-color: SiteNavBg;
    /*box-shadow: 0 0 6px 0 rgba(0, 0, 0, .12), 0 10px 12px 0 rgba(170, 182, 206, .2), inset 0 -1px 0 0 rgba(255, 255, 255, .3);*/
    /*-webkit-box-shadow: 0 0 1px 0 #e8e9eb;*/
    /*-moz-box-shadow: 0 0 1px 0 #e8e9eb;*/
    /*box-shadow: 0 0 1px 0 #e8e9eb;*/
    border-left: 1px solid SIDEBORDER_COLOR;
    border-bottom: 1px solid SIDEBORDER_COLOR;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    min-width: MinWidth;
}

.logoWraper {
    width: 300px;
    display: inline-block;
}

.logo {
    display: inline-block;
    width: 120px;
    height: 48px;
    margin: 6px 12px 0 20px;
    background: url(../../images/logo.svg) no-repeat;
}

.slogan {
    display: inline-block;
    width: 130px;
    height: 20px;
    background: url(../../images/slogan@2x.png) no-repeat;
    background-size: 100%;
    position: relative;
    top: -8px;
}

.hdSet {
    float: right;
    height: SiteNavHeight;
    margin-right: 16px;
}

.subsHeadImg img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-right: 6px;
}

.hdSet .hdSetItem {
    min-width: 70px;
    margin-left: 20px;
    text-align: center;
    cursor: pointer;
    float: left;
    line-height: SiteNavHeight;
}

.exit {
    color: #FB607E !important;
}

.proExprie {
    line-height: 40px !important;
    text-align: center;
    padding: 0 20px;
    color: #FC9F2D;
}

.userDrop {
    width: 240px;
}

.helpwrp li {
    float: left;
}

.textIcon {
    text-align: left;
    padding-left: 22px;
    display: inline-block;
    height: SiteNavHeight;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    color: black;
}

.textIcon:hover {
    color: BASE_COLOR;
}

.helpIcon {
    background-image: url(../../images/help.png);
}

.helpIcon:hover {
    background-image: url(../../images/help_hover.png);
}

.adviceIcon {
    background-image: url(../../images/advice.png);
}

.adviceIcon:hover {
    background-image: url(../../images/advice_hover.png);
}

.split {
    display: inline-block;
    height: 16px;
    width: 1px;
    background-color: #D2D2D2;
    margin: 0 10px;
    position: relative;
    top: 2px;
}

.noticePanel{
    padding: 20px;
    min-height: 84vh;
}
.noticePanel h3{
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    font-size: 18px;
}
.noticeItem{
    border-bottom: 1px solid #f0f0f0;
    padding: 10px;
    cursor: pointer;
}

.noticeItem:hover{
    background: #fafafa;
}