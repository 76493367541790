@value variables: "../../css/variables.css";

@value BASE_COLOR, bodyBg from variables;

.bg {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    overflow: hidden;
}

.fx {
    width: 100%;
    height: 100%;
}

.header {
    width: 1100px;
    margin: 0 auto;
    height: 70px;
    position: relative;
}

.headerRight {
    width: 66px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 10px;
}

.avatar {
    height: 32px;
    width: 32px;
    border-radius: 32px;
}

.title {
    text-align: center;
    color: #232735;
    font-weight: 700;
    font-size: 30px;
    padding: 30px 0;
}

.stats {
    width: 1100px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 30px;
}

.dataItem {
    background-color: white;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: center;
    padding: 20px 0;
    font-weight: 700;
}

.dataItem h3 {
    margin: 0 0 20px 0;
    color: #909298;
}

.dataItem p {
    color: #00ABFF;
    font-size: 24px;
    margin: 0;
}

.tc {
    background-color: white;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

/*.tc table {*/
/*width: 100%;*/
/*text-align: center;*/
/*}*/

/*.tc th {*/
/*background-color: #7862F3;*/
/*color: white;*/
/*text-align: center;*/
/*padding: 10px 0;*/
/*border: 1px solid white;*/
/*}*/

/*.tc td {*/
/*padding: 8px 0;*/
/*background-color: rgba(120, 98, 243, 0.3);*/
/*border: 1px solid white;*/
/*font-weight: 700;*/
/*color: #5f5f5f;*/
/*}*/

.norecord {
    text-align: center;
    margin-top: 10px;
    color: #5f5f5f;
    width: 100%;
}

.signinQrcode {
    width: 220px;
    height: 220px;
    margin: 0 auto;
}

.signinQrcode img {
    width: 100%;
    height: 100%;
}

.tip {
    text-align: center;
    padding-top: 10px;
    color: #8c8c8c;
}