@value variables: "../../css/variables.css";

@value SiteNavHeight, BASE_COLOR from variables;

.auth {
    width: 100%;
    height: 300px;
    position: absolute;
    top: calc(50% - 150px);
    text-align: center;
}

.header {
    width: 100%;
    height: SiteNavHeight;
    margin-bottom: 150px;
    height: SiteNavHeight;
    line-height: SiteNavHeight;
}

.logo {
    float: left;
    font-size: 32px;
    color: #175199;
    width: 120px;
    height: 48px;
    background: url(../../images/logo.svg) no-repeat;
}

.account {
    float: right;
}

.authStatusImg {
    width: 64px;
    height: 64px;
    background-size: 100%;
    margin: 0 auto 40px auto;
    background-repeat: no-repeat;
}

.successImg {
    background-image: url(../../images/auth_success@2x.png);
}

.failImg {
    background-image: url(../../images/audit_fail_1@2x.png);
}

.successText {
    font-weight: 600;
    font-size: 16px;
}

.authBtn {
    width: 220px;
    height: 46px;
    line-height: 46px;
    margin-top: 20px;
}

.footer {
    position: absolute;
    bottom: 50px;
    text-align: center;
    width: 100%;
}

.login {
    color: BASE_COLOR;
}

.login:hover {
    color: BASE_COLOR;
}