@value variables: "../../css/variables.css";

@value siteNavHeight , BorderColor from variables;

@value HeaderWraperWidth: 700px;

.fpHead {
    height: siteNavHeight;
    background-color: #dfdfdf;
}

.fpHead .pageTitle, .signflow {
    display: inline-block;
    vertical-align: middle;
}

.fpHeadWraper {
    width: HeaderWraperWidth;
    margin: 0 auto;
    height: 100%;
}

.pageTitle {
    border-left: 1px solid #929292;
    color: #222;
    font-size: 20px;
    height: 30px;
    line-height: 30px;
    padding-left: 25px;
    margin-left: 25px;
}

.signflow {
    float: right;
    line-height: siteNavHeight;
}

.fpFormContainer {
    top: calc(50% - 280px);
}

.resultImg {
    width: 60px;
    height: 60px;
    margin: 0 auto 20px auto;
}

.resultImg img {
    width: 100%;
    height: 100%;
}

.checkSuccessText {
    width: 247px;
    font-size: 14px;
    color: #232735;
    text-align: center;
    margin: 60px auto 40px auto;
}

.fpForm {
    width: 400px;
    margin: 50px auto;
}

.formTitle {
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 30px;
    border-bottom: 1px solid BorderColor;
}

.verifycode {
    position: absolute;
    right: 0;
    bottom: 4px;
    cursor: pointer;
}

.verifycode img {
    width: 90px;
}

.verifycodeInput {
    padding-right: 130px;
}
