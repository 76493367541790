:global(.msgbox) {
    width: 500px;
    text-align: center;
    position: fixed;
    top: -50px;
    left: calc(50% - 250px);
    z-index: 9000;
}

:global(.msgbox .msgbox-cotent) {
    display: inline-block;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.06);
    width: auto;
    height: 40px;
    line-height: 40px;
    background-size: 20px;
    padding: 0 10px 0 40px;
    background-repeat: no-repeat;
    background-position: 10px 50%;
    white-space: nowrap;
    margin: 0 auto;
}

:global(.warning-msgbox .msgbox-cotent) {
    background-image: url(../../images/msgbox_warning@2x.png);
}

:global(.success-msgbox .msgbox-cotent) {
    background-image: url(../../images/msgbox_success@2x.png);
}

:global(.error-msgbox .msgbox-cotent) {
    background-image: url(../../images/msgbox_error@2x.png);
}