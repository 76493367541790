@value variables: "../css/variables.css";

@value BodyBg, BASE_COLOR from variables;

::-ms-clear {
    display: none;
}

::-ms-reveal {
    display: none;
}

body, html {
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    font-family: Helvetica, Tahoma, Arial, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif !important;
}

body {
    background-color: BodyBg !important;
    font-size: 14px;
    color: #232735;
}

img[src=""], img:not([src]) {
    opacity: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

strong {
    font-weight: normal;
}

label {
    font-weight: normal;
    margin: 0;
}

embed {
    line-height: 18px;
    display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}

a, a:hover, a:visited, a:link {
    text-decoration: none;
}

:global(.font-bold) {
    font-weight: bold;
}

:global(.text-red) {
    color: red;
}

:global(.text-white) {
    color: white;
}

:global(.text-success) {
    color: #37DDAC;
}

:global(.text-info) {
    color: #5BC0DE;
}

:global(.text-gray) {
    color: #909298;
}

:global(.text-bold) {
    font-weight: bold !important;
}

:global(.cursor-pointer) {
    cursor: pointer;
}

:global(.tooltip-inner) {
    max-width: 500px !important;
}

:global(.page-header) {
    border: none;
}

:global(.checkBox input[type="checkbox"]) {
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    display: none
}

:global(.checkBox) {
    display: inline-block;
    cursor: pointer;
    height: 20px;
    line-height: 20px;
    font-weight: 500;
}

:global(.checkBox input[type="checkbox"] + span) {
    width: 20px;
    height: 20px;
    background: url(../images/checkbox.png) no-repeat;
    background-position: 0 0;
    float: left;
    margin-top: 2px;
    margin-right: 6px;
}

:global(.checkBox input[type="checkbox"]:checked + span) {
    background-position: 0 -20px
}

:global(.no-did) {
    margin-top: 20px;
    text-align: center;
}

:global(.display-inline-block) {
    display: inline-block;
}

:global(.noborder) {
    border: none !important;
}

@media (min-width: 992px) {
    :global(.modal-lg) {
        width: 1200px;
    }
}

:global(.space) {
    height: 20px;
}

:global(#container) {
    height: 100%;
    width: 100%;
}

:global(.formTitle) {
    font-weight: bold;
    font-size: 14px;
    margin: 20px 0;
    border-left: 4px solid #14CC76;
    padding-left: 10px;
}

:global(.tooltip-inner) {
    white-space: nowrap;
}

:global(.picker) {
    z-index: 999999 !important;
}

:global(.bgWhite){
    background: #fff;
}

:global(.pl10) {
    padding-left: 10px;
}

:global(.pr10) {
    padding-right: 10px;
}

:global(.pt10) {
    padding-top: 10px;
}

:global(.pb10) {
    padding-bottom: 10px;
}

:global(.pl20) {
    padding-left: 20px;
}

:global(.pr20) {
    padding-right: 10px;
}

:global(.pt20) {
    padding-top: 20px;
}

:global(.pb20) {
    padding-bottom: 20px;
}

:global(.pl30) {
    padding-left: 30px;
}

:global(.pr30) {
    padding-right: 30px;
}

:global(.mb0) {
    margin-bottom: 0;
}
:global(.pl10){padding-left: 10px;}
:global(.pr10){padding-right: 10px;}
:global(.pt10){padding-top: 10px;}
:global(.pb10){padding-bottom: 10px;}
:global(.pl20){padding-left: 20px;}
:global(.pr20){padding-right: 10px;}
:global(.pt20){padding-top: 20px;}
:global(.pb20){padding-bottom: 20px;}
:global(.pl30){padding-left: 30px;}
:global(.pr30){padding-right: 30px;}
:global(.mb0){margin-bottom: 0;}
:global(.border-none){border: none !important;}
:global(.minHeight){min-height: 85vh;}

:global(.titleTip){
    position: relative;
    padding-left: 11px;
}
:global(.titleTip:before){
    content: '';
    width: 3px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -7px;
    background: #0d7fff;
}