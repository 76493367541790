@value variables: "../../css/variables.css";

@value BASE_COLOR from variables;

.signContainer {
    background: #FFFFFF;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    width: 440px;
    padding: 34px 70px;
}

.signinContainer {
    top: calc(50% - 280px);
}

.container {
    position: absolute;
    left: calc(50% - 220px);
    width: 400px;
}

.signBg {
    width: 100%;
    height: 100%;
    background-color: #F8F8FA;
}

.signupContainer {
    top: calc(50% - 320px);
}

.formTitle {
    font-size: 20px;
    color: #232735;
    text-align: center;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 30px;
}

.logo {
    padding-bottom: 80px;
    height: 64px;
    text-align: center;
    font-size: 40px;
}

.logoWhite {
    margin: 0 0 40px 113px;
    width: 214px;
    height: 86px;
    background-image: url(../../images/logo3@2x.png);
    background-size: 100%;
    background-repeat: no-repeat;
}

.logoWhite img {
    width: 100%;
    height: 100%;
}

.signInputContainer {
    position: relative;
}

.signInput {
    flex: 1;
    padding: 0 10px;
    overflow: hidden;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    background: transparent !important;
    border: none;
    border-bottom: 1px solid #E5E6E9;
    outline: none;
    resize: none;
    height: 44px;
    line-height: 48px;
    width: 100%;
}

.signInput::placeholder {
    font-size: 14px;
    color: #B0B2B8;
}

.signInput::-webkit-input-placeholder {
    font-size: 14px;
    color: #B0B2B8;
}

.signInput:-moz-placeholder {
    font-size: 14px;
    color: #B0B2B8;
}

.inputNullMask {
    background-color: #FFFFFF;
    position: absolute;
    top: 10px;
    left: 10px;
    color: #f75659;
    padding-right: 20px;
}

.inputErrMask {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #f75659;
}

.inputRegCodeErr {
    position: absolute;
    top: 10px;
    left: 80px;
    color: #f75659;
}

.inputMaskFull {
    width: 100%;
}

.inputBaffle {
    position: absolute;
    top: 14px;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: #FFFFFF;
}

.inputWraper {
    width: 100%;
    margin-top: 20px;
    position: relative;
}

.submitBtn {
    width: 100%;
    margin-top: 30px;
    height: 36px;
}

.regDeclar {
    color: #9aaabf;
}

.regDeclar a {
    color: #8590a6;
}

.regFooter {
    margin-top: 16px;
}

.signSwitch {
    text-align: center;
    font-size: 14px;
    color: #232735;
}

.signSwitch a {
    color: BASE_COLOR;
    cursor: pointer;
}

.forgetPwd {
    float: right;
    font-size: 14px;
    color: #909298;
    cursor: pointer;
}

.forgetPwd:hover {
    color: BASE_COLOR;
}

.cannotLogin {
    margin-top: 10px;
    color: #f75659;
    height: 20px;
}

.smsButton {
    color: #175199;
    display: inline-block;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    line-height: inherit;
    background-color: transparent;
    border: none;
    border-radius: 0;
    outline: none;
}

.smsButton:hover {
    color: #7a8599;
}

.smsButtonDisable {
    color: #9aaabf;
}

.signBtn {
    margin: 20px 0 !important;
    height: 40px !important;
    line-height: 40px !important;
    display: block !important;
    width: 100%;
}

.regFooter{
    margin-bottom: 16px;
}

.regDeclar a:hover{
    color: BASE_COLOR;
}