.pwarn {
    width: 342px;
    background-color: #FFFFFF;
    border: 1px solid #F8F8FA;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    position: fixed;
    top: 80px;
    right: 20px;
    z-index: 20;
    padding: 10px 20px 20px 20px;
}

.header {
    height: 20px;
    margin-right: -10px;
}

.header .close {
    display: block;
    height: 20px;
    width: 20px;
    background-image: url(../../images/btn_close@2x.png);
    background-size: 20px;
    background-repeat: no-repeat;
    float: right;
    cursor: pointer;
}

.body {
    font-size: 14px;
    color: #232735;
    line-height: 24px;
    margin: 10px 0;
}

.body p {
    margin: 0;
    padding: 0;
}

.footer {
    height: 40px;
}

.footer button {
    float: right;
}

.expiredText {
    font-size: 16px;
    color: #232735;
    text-align: center;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 40px;
}

.expiredText p {
    margin: 0;
}

.expiredPur {
    text-align: center;
}

.expiredPur button {
    width: 160px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 10px;
}